.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
  }

.error-container{
  width: 100vw;
  height: 100vh;
  background-color: #cbf4ff86;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-h{
  text-align: center;
  font-size: 22px;
}

.try-again-btn{
  background-color: #61dafb;
  border: 0px solid;
  padding: 6px 22px 6px 22px;
  font-weight: 700;
  font-size: medium;
  border-radius: 12px;
  color: aliceblue;
}

*{
  scroll-behavior: smooth;
}