.not-found-bg{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .notFound{
        width: 30vw;
        background-size: cover;
    }
    .not-f-heading{
        font-size: 2vw;
        line-height: 2.1vw;
        font-weight: 500;
        color: #363636;
    }
    .not-f-description{
        font-size: 1.4vw;
        line-height: 1.5vw;
        font-weight: 300;
        color: #999999;
    }
}

@media screen and (width < 820px) {
    .notFound{
        width: 50vw !important;
    }
    .not-f-heading{
        font-size: 4vw !important;
        line-height: 4.1vw !important;
    }
    .not-f-description{
        font-size: 2vw !important;
        line-height: 2.1vw !important;
    }
}