/* Navbar styles */
.myHome {
    // background-color: #f2fafa;
  }
  /* Navbar  */
  .mynavbar {
    border-bottom: 0.1px solid gray;
    background-color: #f2fafa;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      font-size: 24px;
      font-weight: bold;
    }
    .buttonLogin {
      background-color: #def2f2;
      border-radius: 40px;
      padding: 10px 20px;
      border: none;
      transition: all 0.3s ease;
    }
    .buttonLogin:hover {
      background: #29d1d6;
    }
    .buttonSignup {
      background-color: #29d1d6;
      border-radius: 40px;
      padding: 10px 20px;
      border: none;
      transition: all 0.3s ease;
    }
    .buttonSignup:hover {
      background: #f9f9f9;
      border: 1px solid rgb(75, 141, 141);
    }
    .button-container {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      gap: 10px;
      /* border: 1px solid red; */
    }
  }
  /* Main  */
  .Main {
    width: 80vw;
    margin: auto;
    .HeroSection {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .herotxt {
        padding: 0 5px;
        .herotxt-op {
          margin-right: 50px;
          font-weight: 800;
          font-size: 49px;
        }
        @media (max-width: 420px) {
          .herotxt-op {
            margin-right: 0px;
            font-weight: 800;
            font-size: 42px;
            /* border: 1px solid red; */
          }
        }
        .buttonSignupok {
          margin-top: 20px;
          background-color: #29d1d6;
          border-radius: 40px;
          padding: 10px 20px;
          border: none;
        }
      }
    }
    @media (max-width: 1357px) {
      .HeroSection {
        flex-direction: column;
      }
      .herolaptopimahe {
        width: 100%;
        height: 400px;
      }
    }
    @media (max-width: 521px) {
      .herolaptopimahe {
        height: 250px;
      }
    }
    @media (min-width: 1357px) {
      .herotxt-h1 {
        width: 500px;
      }
    }
    .radio-txt{
      margin-top: 10px;
      font-size: x-large;
      font-weight: 800;
    }
    @media (max-width: 500px) {
      .radio-txt{
        margin-top: 25px;
      }
    }
    .radioblock{
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid red;
    }
  
    /* Start  */
    .Start {
      margin-top: 40px;
      .txt {
        font-weight: 900;
        font-size: 25px;
      }
      @media (max-width: 397px) {
        .txt {
          font-size: 20px;
        }
      }
      .card-container-steps {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .card1-steps {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 5px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card1-steps {
            width: 200px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 1025px) {
          .card1-steps {
            width: 100%;
            padding: 0 20px;
          }
        }
        .card2-steps {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 5px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card2-steps {
            width: 200px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 1025px) {
          .card2-steps {
            width: 100%;
            padding: 0 20px;
          }
        }
        .card3-steps {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 5px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card3-steps {
            width: 200px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 1025px) {
          .card3-steps {
            width: 100%;
            padding: 0 20px;
          }
        }
        .card4-steps {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 5px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card4-steps {
            width: 200px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 1025px) {
          .card4-steps {
            width: 100%;
            padding: 0 20px;
          }
        }
      }
      @media screen and (min-width: 0px) and (max-width: 1025px) {
        .card-container-steps {
          flex-direction: column;
          align-items: center;
          height: 650px;
        }
      }
    }
  
    /* Subscription  */
    .Subs {
      margin-top: 40px;
      .txt {
        font-weight: 900;
        font-size: 25px;
      }
      .card-container-subs {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        .card1-subs {
          border: 1px solid #c9e8e8;
          width: 400px;
          height: 180px;
          border-radius: 10px;
          transition: transform 0.5s ease;
        }
        @media (max-width: 1314px) {
          .card1-subs {
            width: 350px;
          }
        }
        @media screen and (min-width: 508px) and (max-width: 959px) {
          .card1-subs {
            width: 400px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 508px) {
          .card1-subs {
            width: 100%;
          }
        }
        .card2-subs {
          border: 1px solid #c9e8e8;
          width: 400px;
          height: 180px;
          border-radius: 10px;
          transition: transform 0.5s ease;
        }
        @media (max-width: 1314px) {
          .card2-subs {
            width:350px;
          }
        }
        @media screen and (min-width: 508px) and (max-width: 959px) {
          .card2-subs {
            width: 400px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 508px) {
          .card2-subs {
            width: 100%;
          }
        }
        .card3-subs {
          border: 1px solid #c9e8e8;
          width: 350px;
          height: 180px;
          border-radius: 10px;
          transition: transform 0.5s ease;
        }
        @media (max-width: 1314px) {
          .card3-subs {
            width: 250px;
          }
        }
        @media screen and (min-width: 508px) and (max-width: 959px) {
          .card3-subs {
            width: 400px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 508px) {
          .card3-subs {
            width: 100%;
          }
        }
      }
      @media screen and (min-width: 0px) and (max-width: 959px) {
        .card-container-subs {
          flex-direction: column;
          align-items: center;
          height: 400px;
        }
      }
    }
    /* Feature  */
    .Feature {
      margin-top: 40px;
  
      .txt {
        font-weight: 900;
        font-size: 25px;
      }
      .txt1 {
        margin-top: 10px;
        font-weight: 900;
        font-size: 34px;
      }
      .txt2 {
        font-weight: 500;
        font-size: 16px;
      }
      .card-container-feature {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .card1-feature {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 10px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card1-feature {
            width: 250px;
          }
        }
        @media screen and (min-width: 616px) and (max-width: 959px) {
          .card1-feature {
            width: 500px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 616px) {
          .card1-feature {
            width: 99%;
          }
        }
        .card2-feature {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 10px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card2-feature {
            width: 250px;
          }
        }
        @media screen and (min-width: 616px) and (max-width: 959px) {
          .card2-feature {
            width: 500px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 616px) {
          .card2-feature {
            width: 99%;
          }
        }
        .card3-feature {
          border: 1px solid #c9e8e8;
          width: 250px;
          height: 150px;
          border-radius: 10px;
          transition: transform 0.3s ease;
        }
        @media (max-width: 1314px) {
          .card3-feature {
            width: 250px;
          }
        }
        @media screen and (min-width: 616px) and (max-width: 959px) {
          .card3-feature {
            width: 500px;
          }
        }
        @media screen and (min-width: 0px) and (max-width: 616px) {
          .card3-feature {
            width: 99%;
          }
        }
      }
      @media screen and (min-width: 0px) and (max-width: 959px) {
        .card-container-feature {
          gap: 20px;
          flex-direction: column;
          height: 620px;
        }
      }
    }
    /* About  */
    .About {
      margin-top: 40px;
  
      .txt {
        font-weight: 900;
        font-size: 25px;
      }
      .txt1 {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .aboutimg {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    /* Footer  */
    .Footer {
      margin-top: 20px;
      .footer1 {
        display: flex;
        justify-content: space-around;
      }
      .footer2 {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 20px;
      }
      .footer3 {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .footer4 {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;
        /* align-items: center; */
        /* flex-direction: column; */
        font-size: small;
      }
      .footer4 a
      {
        text-decoration: none;
        color: black;
      }
      @media(max-width:530px)
      {
        .footer4
        {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 130px;
        }
        #mail-name-id-div
        {
          font-size: 15px;
        }
        #cin-gst-id-div
        {
          font-size: 15px;
        }
      }
    }
  }
  .signuplink:hover {
    text-decoration: none;
  }
  .signuplink {
    color: black;
    font-weight: bolder;
  }
  .loginlink:hover {
    text-decoration: none;
  }
  .loginlink {
    color: black;
    font-weight: bolder;
  }
  .getstartedlink:hover {
    text-decoration: none;
  }
  .getstartedlink {
    color: black;
    font-weight: bolder;
  }
  #facebook-link a {
    font-size: 29px;
    color: black;
    transition: all 0.3s ease;
  }
  #twitter-link a {
    font-size: 29px;
    color: black;
    transition: all 0.3s ease;
  }
  #insta-link a {
    font-size: 29px;
    color: black;
    transition: all 0.3s ease;
  }
  .card1-steps div {
    padding: 5px;
  }
  .card1-steps .profile-icon {
    font-size: 35px;
  }
  .card1-steps b {
    font-size: 20px;
  }
  .card1-steps p {
    color: #29d1d6;
  }
  .card2-steps div {
    padding: 5px;
  }
  .card2-steps .color-board {
    font-size: 35px;
  }
  .card2-steps b {
    font-size: 20px;
  }
  .card2-steps p {
    color: #29d1d6;
  }
  .card3-steps div {
    padding: 5px;
  }
  .card3-steps .cart-logo {
    font-size: 35px;
  }
  .card3-steps b {
    font-size: 20px;
  }
  .card3-steps p {
    color: #29d1d6;
  }
  .card4-steps div {
    padding: 5px;
  }
  .card4-steps .globe-logo {
    font-size: 35px;
  }
  .card4-steps b {
    font-size: 20px;
  }
  .card4-steps p {
    color: #29d1d6;
  }
  .card1-subs .first-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .card1-subs #price-monthly {
    display: flex;
    flex-direction: row;
  }
  #basic-tag {
    font-size: 20px;
    font-weight: 900;
  }
  .choose-basic {
    margin: auto;
    padding: 5px 40px;
    border-radius: 20px;
    background: #79eef3;
    box-shadow: 2px 4px 4px lightblue;
  }
  #price-monthly .tendollar {
    font-size: 40px;
    font-weight: 900;
  }
  #price-monthly {
    align-items: center;
  }
  #price-monthly .permoth {
    font-size: 20px;
    padding: 10px;
    font-weight: 700;
  }
  #bulletpoints-card1 {
    padding: 15px 0;
  }
  .card2-subs .first-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  #pro-tag {
    display: flex;
    font-size: 20px;
    font-weight: 900;
    justify-content: space-between;
    align-items: center;
  }
  .card2-subs #price-monthly {
    display: flex;
    flex-direction: row;
  }
  .most-popular {
    font-weight: 500;
    font-size: 15px;
    border-radius: 10px;
    padding: 0 10px;
    background: #28c5ca;
  }
  .card3-subs .first-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .card3-subs #price-monthly {
    display: flex;
    flex-direction: row;
  }
  #premium-tag {
    font-size: 20px;
    font-weight: 900;
  }
  .card-container-feature .firstdiv {
    padding: 10px;
  }
  .finger-click-icon {
    font-size: 30px;
  }
  .card1-feature b {
    font-size: 20px;
    font-weight: 900;
  }
  .planet-icon {
    font-size: 30px;
  }
  .card2-feature b {
    font-size: 20px;
    font-weight: 900;
  }
  .card3-feature b {
    font-size: 20px;
    font-weight: 900;
  }
  .card1-steps:hover {
    transform: translateY(-20px);
  }
  .card2-steps:hover {
    transform: translateY(-20px);
  }
  .card3-steps:hover {
    transform: translateY(-20px);
  }
  .card4-steps:hover {
    transform: translateY(-20px);
  }
  .card1-subs:hover {
    transform: scale(1.1);
  }
  .card2-subs:hover {
    transform: scale(1.1);
  }
  .card3-subs:hover {
    transform: scale(1.1);
  }
  .card1-feature:hover {
    transform: translateY(-10px);
  }
  .card2-feature:hover {
    transform: translateY(-10px);
  }
  .card3-feature:hover {
    transform: translateY(-10px);
  }
  #facebook-link a:hover {
    color: #28c5ca;
  }
  #twitter-link a:hover {
    color: #28c5ca;
  }
  #insta-link a:hover {
    color: #28c5ca;
  }
  #getstarted-bttn {
    transition: all 0.5s ease;
  }
  #getstarted-bttn:hover {
    background: white;
    border: 1px solid aqua;
  }
  .privacy-policy {
    border: 1px solid aqua;
    padding: 5px 10px;
    border-radius: 30px;
    color: black;
    transition: all 0.3s ease;
  }
  @media screen and (min-width: 0px) and (max-width: 452px) {
    .privacy-policy {
      font-size: 10px;
      font-weight: 900;
    }
  }
  .privacy-policy:hover {
    text-decoration: none;
    background: #28c5ca;
    color: white;
  }
  .terms-and-service {
    border: 1px solid aqua;
    padding: 5px 10px;
    border-radius: 30px;
    color: black;
    transition: all 0.3s ease;
  }
  @media screen and (min-width: 0px) and (max-width: 452px) {
    .terms-and-service {
      font-size: 10px;
      font-weight: 900;
    }
  }
  .terms-and-service:hover {
    text-decoration: none;
    background: #28c5ca;
    color: white;
  }
  .news-letter {
    border: 1px solid aqua;
    padding: 5px 10px;
    border-radius: 30px;
    color: black;
    transition: all 0.3s ease;
  }
  @media screen and (min-width: 0px) and (max-width: 452px) {
    .news-letter {
      font-size: 10px;
      font-weight: 900;
    }
  }
  .news-letter:hover {
    text-decoration: none;
    background: #28c5ca;
    color: white;
  }
  .peopleondesk {
    width: 45%;
  }
  
  /* meida scree  */
  @media screen and (min-width: 0px) and (max-width: 462px) {
    .herotxt-p {
      font-size: 25px;
      margin-right: 0;
    }
  }
  @media screen and (min-width: 462px) and (max-width: 900px) {
    .herotxt-p {
      font-size: 35px;
      margin-right: 0;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 1355px) {
    .herotxt {
      margin-top: 10px;
    }
  }
  @media (max-width: 1400px) and (min-width: 1180px) {
    .herotxt {
      margin-left: 100px;
      margin-top: 20px;
      /* border: 1px solid red; */
    }
  }
  
  .emitratandc-guide
  {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #emitra-t-c-bttn
  {
    padding: 5px 10px;
    margin-top: 5px;
    border: none;
    background: #28c5ca;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  #emitra-t-c-bttn:hover{
    background: white;
    color: #28c5ca;
    border: 1px solid #28c5ca;
  }
  #emitra-guide-bttn
  {padding: 5px 10px;
    border: none;
    background: #28c5ca;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  @media(max-width:647px)
  {
    #emitra-guide-bttn
    {
        font-size: 14px;
    }
    #emitra-t-c-bttn
    {
        font-size: 14px;
    }
  }
  #emitra-guide-bttn:hover
  {
    background: white;
    color: #28c5ca;
    border: 1px solid #28c5ca;
  }
  .emitratandc-guide
  {
    padding-top:20px;
  }
  #emitra-guidebttn
  {
    color: black;
    text-decoration: none;
  }
  #emitra-guidebttn:hover{
    color: #29d1d6;
  }
  .buttonSignupok {
    margin-top: 20px;
    background-color: #29d1d6;
    border-radius: 40px;
    padding: 10px 20px;
    border: none;
    margin-right: 50px;
  }
  