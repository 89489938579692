.cart-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    .cart-item-img-details-container{
        gap: 5px;
        display: flex;
        .cart-item-image{
            height: 150px;
            width: 150px;
            background-size: cover;
            border-radius: 8px;
            position: relative;
            .tick-box{
             position:absolute;
             top: 5px;
             left: 5px;
             .checkbox{
                height: 15px;
                width: 15px;
             }
            }
        }
        
        .cart-item-details-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 1rem;
            .product-name{
                font-size: 24px;
                margin-bottom: 0;
                padding-top: 0;
                font-weight: 500;
                color: #0293a8;
            }
            .mini-desc{
                font-size: 13px;
                font-weight: 500;
                color: #1D1D1D;
            }
            .size-color-shop-contollers-container{
                display: flex;
                .size-color-shop-container{
                    display: flex;
                    .seller-name{
                        color: #999999;
                        font-size: 13px;
                        font-weight: 300;
                    }
                    .size-color-container{
                        display: flex;
                        overflow: hidden;
                        overflow-x: scroll;
                        .size-container{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px 10px 0px 10px;
                            height: 22px;
                            border: 1px solid #DADADA;
                            padding-bottom: 0;
                            font-size: 12px;
                            border-radius: 12px;
                            margin-right: 8px;
                            flex-shrink: 0;
                            .size-h{
                                line-height: 12px;
                            }
                            .size{
                                font-size: 14px;
                                margin-left: 6px;
                                font-weight: 800;
                            }
                        }
                        .color-container{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px 10px 0px 10px;
                            height: 22px;
                            border: 1px solid #DADADA;
                            padding-bottom: 0;
                            font-size: 12px;
                            border-radius: 12px;
                            flex-shrink: 0;
                            .color-h{
                                line-height: 12px;
                            }
                            .Color-ind{
                                height: 14px;
                                width: 14px;
                                border: 1px solid #DADADA;
                                margin-left: 5px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
            .count-controlls{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 80px;
                    height: 22px;
                    border-radius: 12px;
                    min-width: 100px;
                    border: 1px solid #DADADA;
                    flex-shrink: 0;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    .qnt{
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
                    }
                    .controll-btn{
                        width: 13px;
                        height: 13px;
                        font-size: 12px;
                        font-weight: 700;
                        cursor: pointer;
                        border: 0px solid;
                        background-color: transparent;
                    }
                    .product-count{
                        line-height: 13px;
                        font-size: 13px;
                        margin-bottom: -2px !important;
                    }
                }
            .product-price{
                font-size: 14px;
                font-weight: 800;
            }
            .out-of-stock{
                font-size: 14px;
                color: red;
            }
        }
    }
    .delete-icon{
        font-size: 18px;
        cursor: pointer;
        align-self: flex-start;
        margin-right: 12px;
    }
}

.delete-icon:hover{
        color: #FF7D34;       
    }

@media screen and (max-width: 820px) {
    .cart-item{
        .cart-item-img-details-container{
            
            .cart-item-image{
                
            }
            .product-name{
                font-size: 12px !important;
            }
            .product-price{
                font-size: 12px;
            }
            .out-of-stock{
                font-size: 10px;
            }
            .delivery-date{
                font-size: 10px;
            }
        }
            .count-controlls{
                min-width: 60px;
                .controll-btn{
                    width: 14px !important;
                    height: 14px !important;
                    font-size: 11px;
                }
            }
    }
    .delete-icon{
        font-size: 20px !important;
    }
}

@media screen and (max-width: 820px){
    .cart-item .cart-item-img-details-container .cart-item-image{
        height: 100px !important;
        width: 100px !important;
    }
}

@media screen and (max-width: 340px){
    .cart-item .cart-item-img-details-container .cart-item-image{
        height: 70px !important;
        width: 70px !important;
    }
    .cart-container .cart-items-bg-container .address-payment-details .payment-details .price-details{
        font-size: 16px !important;
    }
    .cart-container .cart-items-bg-container .address-payment-details .payment-details .bill .total-discount-amount{
        font-size: 14px !important;
    }
}