.invoiceHeading, .invoiceSubHeading{
    text-align: center;
}
.invoiceHeading{
    font-size: 25px;
    font-weight: 400;
}
.invoiceFirstBox{
    display: flex;
    justify-content: center;
}
.invoice-left, .invoice-right{
    padding: 20px;
    margin: 10px;
    border: 1px solid black;
}
.invoice-left{
    width: 30%;
}
.invoice-left img{
    width: 100%;
}
.invoice-right{
    width: 30%;
}
.invoiceSecondBox{
    display: flex;
    justify-content: center;
    // padding-left: 246px;
}
.invoiceSecondBox-content{
    // border: 1px solid black;
    width: 61%;
}

.invoice-dashboard {
    display: flex;
    flex-direction: column;
    // background-color: #D8EFF4;
    margin: 10px 100px;
    padding: 20px;
    border-radius: 5px;
}
.invoice-content {
    border: 1px solid black;
    width: 75%;
    background-color: #FFFFFF;
    padding: 7px 5px;
    display: flex;
    // justify-content: space-between;
    margin-top: 5px;
    border-radius: 3px;
}

.invoice-content div {

    font-size: 14px;
    width: 16.67%;
}
.invoiceheadingouter{
    display: flex;
    justify-content: center;
}
.invoice-headings {
    width: 75%;
    // border: 1px solid red;
    // width: 1046px;
    border: 1px solid black;
    color: #FFFFFF;
    background-color: #0293A8;
    margin-top: 10px;
    padding: 5px;
    display: flex;
    // justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    align-items: center;
}

.invoice-headings div {
    width: 16.67%;
}
.seconLastBox{
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.secondLastBoxContent {
    width: 62%;
}
.invoiceLastBox{
    display: flex;
    justify-content: center;
}
.invoiceLastBoxbtn{
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    width: 61%;
}   
.invoiceBtn{
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    color: white;
}
.viewbtn{
    background-color: rgb(48, 239, 102);
}
.printbtn{
    background-color: rgb(31, 191, 244);
}
.fa-print{
    padding-right:4px;
}
.closebtn{
    background-color: rgb(255, 58, 58);
}