*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // background-color: #dedede;
}
.privacy{
    min-height: 100vh;
    font-family: "Popins";
    padding: 30px 200px;
    background: #f5f5f5;
}
.privacy-horizontal{
    border: 0.5px solid black;
    margin-bottom: 2rem;
}
.privacy-heading{
    text-align: center;
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 25px;
}
.privacy-subheading{
    font-weight: 400;
    font-size: 23px;
    margin: 5px 0;
}
.privacy-subheading-subques{
    font-size: 20px;
    margin: 5px 0;

}
.privacy-subheading-content{
    font-size: 16px;
    text-align: justify;
    margin: 10px 0;
}
@media screen and (max-width: 1100px) {
    .privacy{
        padding: 30px 150px;
    }
    .privacy-heading{
        font-size: 42px;
    }
}
@media screen and (max-width: 900px) {
    .privacy{
        padding: 30px 100px;
    }
    .privacy-heading{
        font-size: 39px;
    }
    .privacy-subheading {
        font-size: 21px;   
    }
}
@media screen and (max-width: 600px) {
    .privacy{
        padding: 30px 80px;
    }
    .privacy-heading{
        font-size: 36px;
    }
    .privacy-heading{
        font-size: 36px;
    }
}
@media screen and (max-width: 400px) {
    .privacy{
        padding: 30px 50px;
    }
    .privacy-heading{
        font-size: 33px;
    }
}