@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@500;700&family=Roboto+Slab:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Madimi+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

@media screen and (max-width: 500px) {
  .market-layout {
    /* background-color: aqua; */
    width: 90%;
    margin: auto;
    .head1 {
      padding-top: 30px;
      color: white;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      font-size: 23px !important;
    }
    .main-para {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 200;
      font-size: 17px;
      color: white;
    }
    .btn-container {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .btn1 {
        border-radius: 10px;
        background: #f2f2f2;
        width: 48%;
        text-align: center;
        padding: 16px 0px;
      }
      .btn2 {
        border-radius: 10px;
        color: white;
        border: 3px solid white;
        width: 100%;
        text-align: center;
        padding: 16px 0px;
        border-radius: 30px;
      }
    }
    .second-para {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 200;
      color: white;
    }
    .input1 {
      background-color: rgba(4, 21, 39, 1);
      width: 100%;
      padding: 15px 20px;
      border-radius: 10px;
      border: 1px solid white;
      margin-bottom: 12px;
      color: #ffffff;
    }
    .continue-btn {
      width: 100%;
      padding: 14px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: white;
      text-align: center;
    }

    .para3 {
      margin-top: 15px;
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 10px;
    }
    .market-card {
      border: 3px solid rgba(117, 117, 117, 1);
      border-radius: 10px;
      height: auto;
      margin-bottom: 20px;
      background: linear-gradient(180deg, #ffffff 0%, #daecfe 63.74%);

      .name {
        /* border: 1px solid red; */
        width: 80%;
        /* margin: auto; */
        margin-left: 40px;
        padding-top: 10px;
        /* text-align: center; */
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 14px;
      }
      .point {
        width: 85%;
        margin: auto;
        font-size: 18px;
        margin-bottom: 10px;
        .tick {
          color: #66a04b;
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }

    .small-card-container {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
      .small-card {
        border: 2px solid #64d5cf;
        border-radius: 10px;
        width: 48%;
        height: 240px;
        .name {
          width: 100%;
          margin: auto;
          text-align: center;
          font-size: 18px;
          font-weight: 800;
          margin-bottom: 10px;
        }
        .point {
          width: 85%;
          margin: auto;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
    .marketplace-footer {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .list1 {
        /* width: 50%; */

        list-style: none;
        .list1-head {
          font-size: 18px;
          font-weight: 500;
          color: black;
        }
        div {
          color: rgba(10, 20, 47, 0.62);
        }
      }
    }
    .small-hr {
      border: 1px solid #bebebe;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .small-head {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .contact-div {
      width: 100%;
      display: flex;
      .contact-span1 {
        color: #bebebe;
        width: 60px;
      }
    }
    .follow {
      display: flex;
      gap: 25px;
    }
  }
  .horizontal-line {
    width: 100%;
    height: 30px;
    background-color: #64d5cf;
    margin-bottom: 20px;
  }
  .img-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .between {
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: "Inter", sans-serif;
    /* font-family: "Roboto", sans-serif; */
    font-size: 18px;
    margin-top: 10px;
  }
  .big-card-img {
    /* height: 200px; */
    /* border: 1px solid red; */
    /* width: 100%; */
    /* margin: auto; */
    margin-left: 40px;
    padding-top: 20px;
    /* text-align: center; */
  }
  .small-card-img {
    padding-top: 10px;

    height: 100px;
    /* border: 1px solid red; */
    width: 100%;
    margin: auto;
    text-align: center;
  }
  /* footer  */
  .new-footer {
    flex-wrap: wrap;
    gap: 20px;
    font-size: small;
    .action {
      font-size: small;
    }
    .mail {
      width: 250px !important;
      /* border: 1px solid red; */
    }
  }
  .size {
    margin-top: 8px;
    font-size: 25px !important;
    color: rgba(0, 87, 255, 1);
  }
  .new-foot {
    flex-wrap: wrap !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    /* border: 1px solid red; */
  }
  .last-foot {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
    font-size: small;
    gap: 10px;
    .gap {
      gap: 26px;
    }
    .size2 {
      font-size: 18px !important;
    }
  }
}
/* FAQComponent.css */

.faq-container {
  margin-bottom: 10px;
}

.faq-question {
  cursor: pointer;
  padding: 10px;
  border-top: 1px solid #cacaca;
}
.chat-now-tag {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.chat-now-tag:hover {
  text-decoration: none;
  color: rgb(4, 190, 17);
}
.faq-toggle {
  float: right;
}

.faq-answer {
  padding: 10px;
}
/* Crousal css  */
.testimonial-carousel {
  width: 100%;
}

.testimonial-item {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-item img {
  width: 50%;
  height: 40%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.5;
}

.testimonial-author {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.swiper-button-prev,
.swiper-button-next {
  /* border: 1px solid red; */
  width: 20px;
  height: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 50%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
  color: black;
  line-height: 20px;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}
.main-bg {
  background-color: rgba(4, 21, 39, 1);
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 50px;
}
.continue-section {
  margin-bottom: 10px;
}
.he {
  margin-top: 10px;
}
.head2 {
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
  margin-bottom: 30px;
  margin-left: 10px;
}
.c-1 {
  background: linear-gradient(
    180deg,
    rgba(255, 241, 217, 0) 2.33%,
    rgba(255, 241, 217, 0.65) 45.4%,
    rgba(255, 241, 217, 0) 100%
  );
}
.c-2 {
  background: linear-gradient(
    180deg,
    rgba(196, 224, 254, 0) 0%,
    rgba(196, 224, 254, 0.65) 52%,
    rgba(196, 224, 254, 0) 100%
  );
}

/* business swiper  */

/* final one piece card  */
@media screen and (max-width: 500px) {
  .businessCrousal2 {
    width: 100%;
    margin-bottom: 50px;
    border-radius: 10px;
    justify-content: space-between;
    background: linear-gradient(180deg, #fffbf3 0%, #fff1d9 100%);
    height: 500px;
    border: 2px solid rgba(204, 204, 204, 1);
    .name {
      padding-top: 40px;
      width: 60%;
      margin: auto;
      text-align: center;
      font-weight: 800;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 14px;
    }
    .point {
      width: 75%;
      margin: auto;
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .img img {
      object-fit: contain;

      /* height: 90px; */
      /* width: 90px; */
    }
    .img {
      margin: auto;
      text-align: center;
      padding-top: 40px;
    }
  }
  .businessCrousal {
    width: 100%;
    margin-bottom: 50px;
    border-radius: 10px;
    justify-content: space-between;
    background: linear-gradient(180deg, #fffbf3 0%, #fff1d9 100%);
    height: auto;
    border: 2px solid rgba(204, 204, 204, 1);
    .name {
      padding-top: 40px;
      width: 60%;

      margin: auto;
      text-align: center;
      font-weight: 800;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 14px;
    }
    .point {
      width: 75%;
      margin: auto;
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .img img {
      /* height: 60px; */
      /* width: 60px; */
      object-fit: contain;
    }
    .img {
      padding-top: 40px;
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 1600px) {
  .businessCrousal2 {
    width: 50% !important;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    border-radius: 10px;
    justify-content: space-between;
    background: linear-gradient(180deg, #fffbf3 0%, #fff1d9 100%);
    height: auto;
    border: 2px solid rgba(204, 204, 204, 1);
    .name {
      padding-top: 40px;
      width: 60%;
      margin: auto;
      text-align: center;
      font-weight: 800;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 14px;
    }
    .point {
      width: 75%;
      margin: auto;
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .img img {
      object-fit: contain;

      height: 90px;
      width: 90px;
    }
    .img {
      margin: auto;
      text-align: center;
      padding-top: 40px;
    }
  }
  .businessCrousal {
    width: 50%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    border-radius: 10px;
    justify-content: space-between;
    background: linear-gradient(180deg, #fffbf3 0%, #fff1d9 100%);
    height: auto;
    border: 2px solid rgba(204, 204, 204, 1);
    .name {
      padding-top: 40px;
      width: 60%;

      margin: auto;
      text-align: center;
      font-weight: 800;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 14px;
    }
    .point {
      width: 75%;
      margin: auto;
      font-size: 20px;
      font-weight: 200;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .img img {
      height: 60px;
      width: 60px;
      object-fit: contain;
    }
    .img {
      padding-top: 40px;
    }
  }
}

/* footer section */
.new-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.callus {
  display: flex;
}
.action {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.size {
  font-size: 45px;
}
.sm-ico {
  margin-right: 10px;
}
.new-foot {
  font-family: "Inter", sans-serif;
  margin-top: 60px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  * {
    color: black;
    /* border: 1px solid red; */
  }
}
.last-foot {
  margin: auto;
  width: 90%;
  padding-bottom: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .gap {
    gap: 20px;
    display: flex;
    * {
      color: black;
    }
  }
  .size2 {
    font-size: 30px;
  }
}
.hr {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid black;
}

/* dekstop  */
.market-layout {
  /* background-color: aqua; */
  width: 90%;
  margin: auto;
  @media screen and (min-width: 500px) {
    .head1 {
      padding-top: 30px;
      color: white;
      font-family: "Inter", sans-serif;
      font-weight: bold;
      font-size: 26px !important;
    }
  }

  @media screen and (min-width: 500px) {
    .main-para {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 200;
      font-size: 20px;
      color: white;
    }
  }
  .btn-container {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .btn1 {
      border-radius: 10px;
      background: #f2f2f2;
      width: 48%;
      text-align: center;
      padding: 16px 0px;
    }
    .btn2 {
      border-radius: 10px;
      color: white;
      border: 3px solid white;
      width: 100%;
      text-align: center;
      padding: 16px 0px;
      border-radius: 30px;
    }
  }
  .second-para {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 200;
    color: white;
  }
  .input1 {
    background-color: rgba(4, 21, 39, 1);
    width: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    border: 1px solid white;
    margin-bottom: 12px;
    color: #ffffff;
  }
  .continue-btn {
    width: 100%;
    padding: 14px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: white;
    text-align: center;
  }

  .para3 {
    margin-top: 15px;
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 10px;
  }
  .market-card {
    border: 3px solid rgba(117, 117, 117, 1);
    border-radius: 10px;
    height: auto;
    margin-bottom: 20px;
    background: linear-gradient(180deg, #ffffff 0%, #daecfe 63.74%);

    .name {
      /* border: 1px solid red; */
      width: 80%;
      /* margin: auto; */
      margin-left: 40px;
      padding-top: 10px;
      /* text-align: center; */
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 14px;
    }
    .point {
      width: 85%;
      margin: auto;
      font-size: 18px;
      margin-bottom: 10px;
      .tick {
        color: #66a04b;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .small-card-container {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    .small-card {
      border: 2px solid #64d5cf;
      border-radius: 10px;
      width: 48%;
      height: 240px;
      .name {
        width: 100%;
        margin: auto;
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 10px;
      }
      .point {
        width: 85%;
        margin: auto;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}
.small-hr {
  border: 1px solid #bebebe;
  margin-top: 20px;
  margin-bottom: 20px;
}

.horizontal-line {
  width: 100%;
  height: 30px;
  background-color: #64d5cf;
  margin-bottom: 20px;
}
.img-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 10px;
}
.between {
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: "Inter", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  font-size: 24px;
  margin-top: 10px;
}
.big-card-img {
  /* height: 200px; */
  /* border: 1px solid red; */
  /* width: 100%; */
  /* margin: auto; */
  margin-left: 40px;
  padding-top: 20px;
  /* text-align: center; */
}
.small-card-img {
  padding-top: 10px;

  height: 100px;
  /* border: 1px solid red; */
  width: 100%;
  margin: auto;
  text-align: center;
}
/* footer  */
.new-footer {
  flex-wrap: wrap;
  gap: 20px;
  font-size: small;
  .action {
    font-size: small;
  }
  .mail {
    width: 250px !important;
    /* border: 1px solid red; */
  }
}
.size {
  margin-top: 8px;
  font-size: 25px !important;
  color: rgba(0, 87, 255, 1);
}
.new-foot {
  flex-wrap: wrap !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  /* border: 1px solid red; */
}
.last-foot {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  font-size: small;
  gap: 10px;
  .gap {
    gap: 26px;
  }
  .size2 {
    font-size: 18px !important;
  }
}
.head5 {
  padding-top: 30px;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 38px;
  text-align: center;
}
.plugic {
  font-size: 80px;
  margin: 0 20px 0 20px;
}

@media screen and (max-width: 500px) {
  .head5 {
    /* border: 1px solid red; */
    font-size: 30px;
    padding-bottom: 10px;
  }
  .plugic {
    font-size: 60px;
    margin: 0 0 0 0;
  }
}
.faq-layout {
  width: 60%;
  margin: auto;
}
@media screen and (max-width: 500px) {
  .faq-layout {
    width: 90%;
    margin: auto;
  }
}
.border9 {
  border-top: 1px solid black;
}
.bg-radial-pattern1 {
  background-image: radial-gradient(
    rgba(93, 46, 213, 0.171) 2px,
    transparent 0
  );
  background-size: 30px 30px;
  background-position: -5px -5px;
}
.fontstyle1 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.fontstyle2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgba(63, 76, 90, 1);
}
.fontstyle3 {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

@media screen and (max-width: 400px) {
  .hello {
    margin-top: 20px;
    margin-bottom: 40px !important;
  }
  .hello2 {
    margin-bottom: 40px !important;
  }
}
