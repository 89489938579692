.Login-Container{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background:  linear-gradient(164.58deg, #97cbd3 1% , #ffffff 100%);;
    .login-header{
        width: 90vw;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .auth-logo{
            width: 260px;
            background-size: cover;
        }
        .signup-login-container{
            display: flex;
            .link{
                text-decoration: none;
                .nav-link{
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #999999;
                    margin-right: 20px;
                    text-decoration: none;
                }
                .active{
                    color: #0293A8;
                    margin-right: 20px;
                    padding: 0px 10px 5px 10px;
                    border-bottom: 4px solid #0293A8;
                }
            }
            
        }
    }
    .body-Container{
        width: 80vw;
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: 100%;
        justify-content: center;
        align-items: center;
        .content-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #0293A8;
            .content-heading{
                font-size: 45px;
                font-weight: 500;
                margin-bottom: 1rem;
            }
            .content-writing{
                font-size: 20px;
                font-weight: 500;
            }
        }
        .buttons-container{
            width: 100%;
            height: 55vh;
            background: linear-gradient(153.72deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 47.4%, rgba(255, 255, 255, 0.55) 98.96%);
           filter: drop-shadow(1px 3px 20px rgba(0, 0, 0, 0.05));
            // box-shadow: 0px 0px 2px 2px #dadada;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            border-radius: 15px;
            .email-number-btns{
                width: 80%;
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .email-btn{
                    width: 100%;
                    min-height: 40px;
                    border: 0px solid;
                    background-color: #ffffff;
                    color: #0293A8;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 15px;
                }
                .or-container{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .hulf-line{
                        width: 40%;
                        color: #999999;
                    }
                    .or{
                        margin: 0px 8px 0px 8px;
                        color: #999999;
                    }
                }
            }
            .already{
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 300;
                .sign{
                    color: #0293A8;
                }
            }
        }
        .email-bg-Container{
            width: 100%;
            height: 55vh;
            box-shadow: 0px 0px 2px 2px #dadada;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            .email-container{
                height: 100%;
                width: 100%;
                display: grid;
                grid-template-columns: 90%;
                grid-template-rows: 90% 10%;
                justify-content: center;
                align-items: center;
                .email-input-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .email-heading{
                        width: 90%;
                        color: #999999;
                    }
                    .email-input{
                        width: 90%;
                        height: 35px;
                        outline: none;
                        padding: 5px;
                        border-radius: 6px;
                        border: 0px solid;
                        margin-bottom: 2rem;
                    }
                    .error-msg{
                        color: crimson;
                        margin: 12px;
                    }
                    .rqs-btn{
                        width: 80%;
                        height: 40px;
                        border: 0px solid;
                        border-radius: 12px;
                        outline: none;
                        background-color: transparent;
                        background-image: linear-gradient(153.72deg, #ACDDDB, #F0FBCF 98.96%);
                        color: #0293A8;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }
                .already{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 13px;
                    .signup{
                        margin-left: 8px;
                        font-size: 14px;
                        color: #0293A8;
                        cursor: pointer;
                    }
                }
            }
        }
        .otp-bg-container{
            flex-shrink: 0;
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 90%;
            grid-template-rows: 90% 10%;
            justify-content: center;
            align-items: center;
            .otp-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .otp-heading{
                    color: #999999;
                    font-weight: 300;
                    width: 100%;
                    margin-bottom: 1rem !important;
                }
                .Otp{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    .otp-field{
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        border: 0px solid;
                        border-radius: 6px;
                        margin-left: 5px;
                        margin-right: 5px;
                        outline: none;
                    }
                }
                .ver-btn{
                    width: 80%;
                    height: 40px;
                    border: 0px solid;
                    border-radius: 12px;
                    outline: none;
                    background-color: transparent;
                    background-image: linear-gradient(153.72deg, #ACDDDB, #F0FBCF 98.96%);
                    color: #0293A8;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
            .already{
                display: flex;
                justify-content: center;
                text-align: center;
                font-size: 13px;
                .signup{
                    font-size: 14px;
                    color: #0293A8;
                    cursor: pointer;
                }
            }
            
        }
    }
    .footer{
        width: 90vw;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #999999;
        .copy-right{
            color: #999999;
            font-size: 16px;
        }
        .social-media-icons-container{
            display: flex;
            align-items: center;
            .icon{
                font-size: 30px;
                margin-right: 12px;
            }
        }
    }
}

@media screen and (width <= 920px) {
    .body-Container{
        grid-template-columns: 60% !important;
        .content-content{
            display: none !important;
        }
        .buttons-container{
            height: 35vh !important;
        }
        // .email-bg-Container{}
    }
    
}

@media screen and (width <= 620px) {
    .nav-link{
        font-size: 14px !important;
    }
    .auth-logo{
        width: 140px !important;
    }
    .body-Container{
        grid-template-columns: 90% !important;
        .buttons-container{
            height: 35vh !important;
        }
    }
    
}

@media screen and (width <= 440px) {
    .otp-field{
        width: 30px !important;
        height: 30px !important;
    }
    .copy-right{
        font-size: 12px !important;
    }
}