.top {
  margin-top: 10rem;
}
.head {
  /* margin-left: 10px; */
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.top1 {
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button3 {
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.button3:hover {
  background-color: #f9fafb;
}

.button3:focus {
  outline: 2px solid rgba(0, 0, 0, 0.1);
  outline-offset: 2px;
}

.button3:focus-visible {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.button-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
