.blocked-con{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .blocked-img{
        width: 40vw;
        background-size: cover;
    }
    .blocked-h{
        font-size: 26px;
        color: #0293A8;
        font-weight: 600;
    }
    .blocked-p{
        font-size: 16px;
        font-weight: 500;
        color: #999999;
    }
}