.w-product-container {
    padding: 6px;
    width: 300px;
    height: 450px;
    margin: 1.5vw;
    border-radius: 15px;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    grid-template-rows: 5% 65% 30%;
    grid-template-columns: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(2, 147, 168, 0.33);
    border-radius: 15px;
    position: relative;
    padding-bottom: 10px;
    .wishlistbuttons {
        display: flex;
        justify-content: flex-end;
        color: black;
        font-size: 20px;
        font-weight: bold;

    }

    .w-img-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        position: relative;

        // border: 1px solid rgba(2, 147, 168, 0.33);
        .w-product-img {
            width: 100%;
            height: max-content;
        }

    }

    .w-product-details-container {
        padding:5px 12px 0px 12px;

        .w-product-name-description-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .w-product-name {
                font-weight: 600;
                font-size: 1.4vw;
                color: #1D1D1D;
                padding-top: 0px;
                .w-product-category {
                    font-size: 1vw;
                    margin-bottom: 0.1%;
                    color: gray;
                }
            }
        }
        .w-cart{
            width:100%;
            border: 2px solid #0293A8;
            color: #0293A8;
            margin-bottom: 5px !important;
            height: 35px;
            background-color: #FFFFFF;
            border-radius: 8px;
        }

        .wishlist-buttons {
            .heart-icon {
                font-size: 20px;
                cursor: pointer;
            }

            cursor: pointer;
        }

        .w-price-container {
            display: flex;
            align-items: center;

            .w-product-normal-price {
                text-decoration: line-through;
                color: #5754546f;
                font-size: 1.1vw;
                margin-right: 1rem;
            }

            .w-product-price {
                font-size: 1.1vw;
                padding-top: 0px;
                margin-bottom: 0px !important;
            }
        }
        .wishlist-btn{
            background-color: #0293A8;
            width: 90%;
            color: white;
            height: 30px;
            margin: auto;
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border-radius: 4px;
            border: none;
        }
    }
}

.w-cross-icon:hover {
    animation: beet;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes beet {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.w-product-container:hover {
    box-shadow: 2px 2px 2px 2px rgb(157, 157, 157);
}

@media screen and (width<=1000px) {
    .w-product-container {
        height: 400px !important;
        padding-bottom: 3rem !important;
    
        .w-img-container {
            height: 100%;
            width: 100%;
        }
    
        .w-product-details-container {
            padding:5px 12px 0px 12px;
    
            .w-product-name-description-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .w-product-name {
                    font-size: 16px !important;
                    .w-product-category {
                        font-size: 11px !important;
                    }
                }
            }
            .w-price-container {
                .w-product-normal-price {
                    font-size: 14px !important;
                }
    
                .w-product-price {
                    font-size: 14px !important;
                }
            }
        }
    }
    
}

@media screen and (width<=845px) {
    .w-product-container {
        height: 350px !important;
        padding-bottom: 3rem !important;
    
        .w-img-container {
            height: 100% !important;
            width: 100%;
            .w-product-img{
                height: 100% !important;
            }
        }
    }
    
}


@media screen and (width<=420px) {
    .w-product-container {
        height: 350px !important;
        grid-template-rows: 7% 69% 24%;

        padding-bottom: 4rem !important;
    
        .w-img-container {
            height: 100% !important;
            width: 100%;
        }
    }
    
}