.swiper-container {
  margin: 10px 10px;
  border-radius: 70px;
  box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
  background: white;
  height: 840px;
}

/* .swiper-slide img {

  width: 100%;
  height: 840px;
} */

/* Adjust space between slides (optional) */
.swiper-slide {
  margin-right: 10px; /* Adjust as needed */
}

/* Center the images horizontally and vertically (optional) */
/* .swiper-slide img {
  display: block;
  margin: 0 auto;
} */

@media screen and (max-width: 767px) {
  /* .swiper-slide img {
    width: 100%;

    height: auto;
  } */
}
.cont {
  background: #fff;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .swiper-container {
    height: 300px;
  }
}
@media screen and (max-width: 580px) {
  .swiper-container {
    height: 240px;
  }
}
@media screen and (max-width: 400px) {
  .swiper-container {
    height: auto;
  }

  /* .swiper-slide img {
    width: 150px;
    height: 200px !important;
  } */
}
