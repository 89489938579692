#player-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
}

.custom-audio-player {
  font-size: 30px;
}
.container-1 {
  width: 400px;
  height: 200px;
  /* background: transparent; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  margin: 10px 0;
}
@media (max-width: 452px) {
  .container-1 {
    width: 90%;
  }
}

.playbutton {
  margin-top: 15px;
  --hover-shadows: 16px 16px 33px #121212, -16px -16px 33px #303030;
  --accent: aqua;
  font-weight: bold;
  letter-spacing: 0.1em;
  border: none;
  border-radius: 1.1em;
  background-color: #212121;
  cursor: pointer;
  color: white;
  padding: 1em 2em;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.playbutton:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}
