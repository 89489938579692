/* Navbar styles and hover styles starts*/
.navbar{
    background-color: white;
    font-family: 'Arbutus Slab';
    font-size: 14px;
  }

  .navbar {
    position: relative;
    transition: border-bottom-color 0.3s ease-in-out;
  }

  .navbar:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #004d00;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  .navbar-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

/* Navbar styles and hover styles start*/



/* Brand or logo (image) styles and hover styles start*/
  .logo-img {
    margin-right: 20px;
      transition: transform 0.3s ease-in-out;
    }


  @keyframes border-animation {
  0% {
      border-color: #b3ffb3;
  }
  25% {
      border-color: #66ff66;
  }
  50% {
      border-color:#00cc00 ;
  }
  75% {
      border-color:#006600 ;
  }
  100% {
      border-color:#001a00 ;
  }
  }
/* Brand or logo (image) styles and hover styles end*/



/* Navbar list (Home, About, Vision, Contact) styles and hover starts*/
  .navbar-nav {
    list-style: none;
    padding: 0;
  }
  
  .nav-item {
    display: inline-block;
    position: relative;
  }
  
  .nav-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #000;
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #000;
    transition: width 0.5s ease-in-out;
  }

  @keyframes underline {
    0% {
      left: 0;
      width: 0;
    }
    25% {
      left: 0;
      width: 100%;
    }
    50% {
      left: 100%;
      width: 0;
    }
    75% {
      left: 0;
      width: 100%;
    }
    100% {
      left: 0;
      width: 100%;
    }
  }

/* User profile or dropdown styles and hover styles starts */
  .fa-regular{
    color: white;
    font-size: 20px;
    padding: 5px;
    background-color: #0293A8;
    border-color: #0293A8 !important;
  }

  .dropdown-menu.dropdown-menu-center {
    display: block;
    margin: 0;
    
    left: -20% !important;
    right: auto !important;
    transform: translateX(-50%) !important;
  }


  .dropdown-item{
    font-family: 'Arbutus Slab';
    font-size: 14px;
    transition: all 0.3s ease;
  }

  .dropdown-item:hover {
    font-size: 15px;
    background-color: white;
  }



/* Media query styles for 576 px size user window starts */  
  @media (max-width: 576px) {
    .nav-item{
        padding-left: 10%;
    }

    .dropdown{
        padding-left: 8%;
    }
    
    .dropdown-menu.dropdown-menu-center {
      left: 30% !important;
      right: 0 !important;
      transform: translateX(0) !important;
    }
  }
/* Media query styles for 576 px size user window ends */

.e-user-details {
  overflow-x: hidden;
    // border: 1px solid red;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;    
}
.e-user-left, .e-user-right{
    // border: 1px solid black;
    margin: 20px;
}
.e-user-right{
    margin-top: 40px;
}
.e-user-left{
    display: flex;
    flex-direction: column;
}
.e-user-upload-img{
    border:0;
    background: #0293A8;
    border-radius: 5px;
    font-family: 'Hind';
    font-size: 16px;
    color: #FFFFFF;
    padding: 5px 10px;
}
.e-user-left img{
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 50%;
}
.e-user{
    // border: 1px solid blue;
    line-height: 1;
}
.e-user-text{
    border: 1px solid #0293A8;
    border-radius: 5px;
    font-size: 16px;
    width: 300px;
    padding: 10px 15px;
}
.e-user span{
    background-color: #ffffff;
    // border: 1px solid green;
    position: relative;
    top: -46px;
    left: 15px;
    font-size: 14px;
    color: #0293A8;
    // transform: translate(15px, -15px);
}
.e-user-dashboard{
    display: flex;
    flex-direction: column;
    background-color: #D8EFF4;
    margin: 10px 100px;
    padding: 20px;
    border-radius: 5px;
}
.e-user-dashboard-first{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.e-user-dashboard-first div{
    font-size: 16px;
    color: #0293A8;
}
.e-user-dashboard-second{
    margin-top:10px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
}
.e-user-filter{
    background-color: #0293A8;
    border-radius: 5px;
    font-size: 18px;
    color: #FFFFFF;
    width: 10%;
}
.filter{
    background-color: #0293A8;
    color: #FFFFFF;
    border-radius: 3px;
    font-size: 16px;
    outline: none;
    border: none;
    padding-left:2px;
}
.e-user-search{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.e-user-search input{
    outline: none;
    border: none;
    padding-left: 10px;
}
.e-user-search input::placeholder{
    color: #0293A8;
}
.e-user-search i{
    color: #d9d9d9;
    cursor: pointer;
    padding-right: 5px;
}
.e-user-table-headings{
    // border: 1px solid red;
    // width: 1046px;
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 5px;
    display: flex;
    // justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    align-items: center;
}
.e-user-table-headings div{
    width: 16.67%;
}
.e-user-table-content{
    background-color: #FFFFFF;
    padding: 7px 5px;
    display: flex;
    // justify-content: space-between;
    margin-top: 5px;
    border-radius: 3px;
}
.e-user-table-content div{
    font-size: 14px;
    width: 16.67%;
}
.e-user-status-paid{
    color: green;
    background-color: rgb(187, 251, 187);
    border-radius: 5px;
    padding: 2px 40px;
}
.e-user-status-unpaid{
    
    color: red;
    background-color: rgb(237, 133, 133);
    border-radius: 5px;
    padding: 2px 30px;

}
@media screen and (max-width:1050px) {
  .e-user-dashboard{
    margin:10px 50px;
  }
}
@media screen and (max-width:600px) {
  .e-user-dashboard{
    margin:10px 30px;
  }
}