.userContainer {
  height: max-content;
  background-color: #def5f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  gap: 20px;
  padding: 30px 50px;
  

  
  .topWrapper {
    display: flex;
    align-items: center;
    gap: 50px;

    .countWrapper {
      display: flex;
      gap: 30px;
      padding: 5px 20px;
      background-color: white;
      border-radius: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      p {
        span {
          font-weight: 700;
        }
      }
      .invisible {
        display: none;
      }

      .visible {
        display: block;
      }
    }
  }
  .btnWrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    button {
      border: none;
      outline: none;
      padding: 5px 20px;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      font-size: 16px;
      font-size: 600;
      background-color: white;
    }
  }

  .subUserWrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;

    .topTable, .bottomTable{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .headingMain{
      display: flex;
      justify-content: space-between;

      .headingSubs{
        font-size: 18px !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      p{
        span{
          font-weight: 700;
        }
      }
    }
    
  }
  .allUserWrapper{
    // position: relative;

    .deletePopUp{
      position: fixed;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      z-index: 999999;
      background-color: white;
      height: 100px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 20px;
      gap: 10px;
      .popBtns{
        display: flex;
        gap: 20px;

        .yesBtn,.cancelBtn{
          border: none;
          outline: none;
          padding: 5px 30px;
          border-radius: 20px;
        }

        .yesBtn{
          background-color: red;
          color: white;
        }
        .cancelBtn{
          background-color: green;
          color: white;
        }
      }
    }
  }
  .allUserWrapper,
  .subUserWrapper,
  .freeUserWrapper {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    height: max-content;
    width: 100%;
    overflow-x: scroll;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    
    th,
    td {
      border: 1px solid #dddddd;
      text-align: center;

      padding: 5px;
    }
    th{
      background-color: #ddd;
      padding: 10px 5px;
    }
    .actionBtn{
        border: 1px solid red;
        outline: none;
        padding: 5px 20px;
        border-radius: 10px;
        background-color: white;
        
        &:hover{
            transition: background-color ease-in .4s;
            color: white;
            background-color: red;
        }
    }
    td{
      .inactiveUser{
        color: red;
        font-weight: 600;
      }

      .activeUser{
        color: green;
        font-weight: 600;
      }
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #ddd;
    }
    .listHeader {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      flex-direction: column;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        overflow-x: scroll;
        font-size: 16px;
        p {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}

/* Add this to your CSS file or style component */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  // translate: -50% -50%;
  // width: ;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;


  .popBtns{
    display: flex;
    justify-content: center;
    gap: 20px;

    .yesBtn,.cancelBtn{
      border: none;
      outline: none;
      padding: 5px 30px;
      border-radius: 20px;
    }

    .yesBtn{
      background-color: red;
      color: white;
    }
    .cancelBtn{
      background-color: green;
      color: white;
    }
  }
}



@media screen and (width <= 620px) {
  .userContainer {
    padding: 10px 20px !important;
  }

  .allUserWrapper,
  .subUserWrapper,
  .freeUserWrapper {
    .listHeader {
      li {
        font-size: 12px !important;
      }
    }
  }

  .topWrapper{
    gap: 20px !important;
    flex-direction: column;
  }
}
