@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@500;700&family=Roboto+Slab:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
svg {
  display: inline-block;
}
.hindifont {
  font-family: "Tiro Devanagari Hindi", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.8;
  /* letter-spacing: 2.2; */
}
h1 {
  font-size: 26px;
  font-weight: 600;
  padding-top: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Hind";
}

.static-container {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin-bottom: 0px !important;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::placeholder {
  color: hwb(0 73% 27%);
  font-weight: 400;
}

button {
  outline: none !important;
  cursor: pointer !important;
}

.robofont {
  font-family: "Roboto Slab", serif;
}
.robofont2 {
  font-family: "Roboto", sans-serif;
}
/* Blogs */
.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}
